import React, {useEffect, useState} from "react"
import BlogProvider from "./context/blogContext";
import BlogPostCard from "./blogPostCard";
import { StaticQuery, graphql } from "gatsby"

const BlogResultsComponent = (props) => {

  const {limit, skip, pageNumbers, currentPage, blogmanagement} = props;

  return(
  <>
  <BlogProvider  limit={limit} skip={skip} pageNumbers={pageNumbers} currentPage={currentPage} blogmanagement={blogmanagement}>
    <BlogPostCard  pageNumbers={pageNumbers} currentPage={currentPage} limit={limit} skip={skip} blogmanagement={blogmanagement}/>
  </BlogProvider>
  </>)

}

export default BlogResultsComponent;