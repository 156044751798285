import React, {useEffect, useState, useContext} from "react";
import { Link } from 'gatsby'
import {BlogContext} from "./context/blogContext";
import { graphql, useStaticQuery } from "gatsby";
import view from "./blog.module.scss"; 

const BlogPostCard = (props) => {

  const {pageNumbers, currentPage, limit, skip, blogmanagement} = props;
  const blogProps = useContext(BlogContext);

  const {firstPage, lastPage, back, next} = blogProps;
  const finalSlug = blogmanagement?.blogResultsPageSlug !== undefined ? blogmanagement?.blogResultsPageSlug : "/blog";


  return(
    <div className={view["pagination-container"]}>
     {!firstPage && (
        <Link className={view["arrow"]} style={{marginRight:5}}  to={back} rel="prev">
           &#5176;
        </Link>
      )}
      {Array.from({ length: pageNumbers }, (_, i) => (
        <Link key={`pagination-number${i + 1}`} to={`${i === 0 ? finalSlug : `${finalSlug}/page/${i + 1}`}`}>
          {i + 1}&nbsp;
        </Link>
      ))}
      {!lastPage && (
        <Link className={view["arrow"]} to={next} rel="next">
         &#5171;
        </Link>
      )}
    </div>

  )
}


export default BlogPostCard;