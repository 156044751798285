import React, {useEffect, useState, useRef} from "react";

//Export context
export const BlogContext = React.createContext();

const BlogProvider = (props) => {

  const {data, pageNumbers, currentPage, blogmanagement} = props;

  const finalSlug = blogmanagement?.blogResultsPageSlug !== undefined ? blogmanagement?.blogResultsPageSlug : "/blog";

  const firstPage = currentPage === 1;
  const lastPage = currentPage === pageNumbers;
  const back = currentPage - 1 === 1 ?  finalSlug : `${finalSlug}/page/${currentPage - 1}`;
  const next = `${finalSlug}/page/${currentPage + 1}`;
  //Props to send through context for sharing between components
  const blogProps = {data, firstPage, lastPage, back, next};

  return (
    <BlogContext.Provider value={blogProps}>
      {props.children}
    </BlogContext.Provider>
  );
}

export default BlogProvider;