import React, {useEffect, useState} from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import BlogResultsComponent from '../components/blogPage/blogResultsComponent'

const BlogResultes = (props, {data}) => {
		const { pageContext } = props
		const post = data
		const { pageContent, pageNumbers, currentPage, limit, skip } = pageContext
		return (
			<Layout>
				<BlogResultsComponent limit={limit} skip={skip} pageContent={pageContent} pageNumbers={pageNumbers} currentPage={currentPage}/>
			</Layout>
		)
}

export default BlogResultes